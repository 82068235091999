import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';

export default function useProducts() {
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    const { data, error, isLoading } = useQuery(
        ['get_app_flags'],
        () => fetchAuth('/client_settings/get_app_flags'),
        {
            retry: false,
            refetchOnMount: false,
            onSuccess: (response) => {
                if (!response || !response || !response.success) {
                    Sentry.captureException(new Error('empty result'), {
                        tags: ['get_app_flags']
                    });
                    return push(SLUGS.error404);
                }
            },
            onError: (err) => {
                Sentry.captureException(err, {
                    tags: ['get_app_flags']
                });
                console.log(err);
                push(SLUGS.error404);
            }
        }
    );

    const dbResponse = data?.data || {};

    return {
        isUsingDemoData: dbResponse.is_using_demo_data,
        isUsingDemoDataForPropensities: dbResponse.is_using_demo_data_for_propensities,
        aiAssistDaysRemaining: dbResponse.ai_assist_days_remaining,
        hasAIAssistPaid: dbResponse.ai_assist_paid,
        isAIAssistAvailable: dbResponse.ai_assist_paid || dbResponse.ai_assist_days_remaining > 0,
        isFiFreeClient: dbResponse.is_fi_free_client,
        isSalesClient: dbResponse.is_sales_client,
        isUsingFIFree: dbResponse.is_using_fi_free,
        hasFIPaid: dbResponse.has_fi_paid,
        hasFIEnabled: dbResponse.has_fi_enabled,
        crmHasFiFree: dbResponse.crm_has_fi_free,
        isDemoClient: dbResponse.is_demo_client,
        hasConnectionsEnabled: dbResponse.has_connections_enabled,
        isIntegrationCompleted: dbResponse.is_integration_completed,
        hasPropensitiesPaid: dbResponse.has_propensities_paid,
        isUsingDemoDataForMidValueReport: dbResponse.is_using_demo_data_for_mid_value_report,
        isUsingDemoDataForMajorDonorReport: dbResponse.is_using_demo_data_for_major_donor_report,
        hasAIAnalystEnabled: dbResponse.has_ai_analyst_enabled,
        hasAIAssistEnabled: dbResponse.has_ai_assist_enabled,
        hasCreateCampaignEnabled: dbResponse.has_create_campaign_enabled,
        hasGoogleAdsEnabled: dbResponse?.has_google_ads_enabled,
        hasMailchimpEnabled: dbResponse.has_mailchimp_enabled,
        hasVirtuousEnabled: dbResponse.has_virtuous_enabled,
        hasCiviCRMEnabled: dbResponse.has_civicrm_enabled,
        hasFacebookEnabled: dbResponse.has_facebook_enabled,
        hasHubSpotEnabled: dbResponse.has_hubspot_enabled,
        hasDuplicateDetectionEnabled: dbResponse.has_duplicate_detection_enabled,
        showMidValueReport: dbResponse.show_mid_value_report,
        showMajorDonorReport: dbResponse.show_major_donor_report,
        showOnboardingBanner: dbResponse.show_onboarding_banner,
        error,
        isLoading
    };
}
